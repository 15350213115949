import React from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import { useState, useEffect }
from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { useCartContext }
from './Context';
import { Outlet, Link, useParams, useNavigate }
from "react-router-dom";

const Ordersuccess = () => {

const login = JSON.parse(localStorage.getItem('USER_INFO'));
        var navigate = useNavigate();
        let {id, ids} = useParams()
        const [book, setBook] = useState([]);
        useEffect(() => {
        if(ids == 3){
        axios.get('https://rank2top.com/api/ebook/' + id)
                .then(response => {
                    setBook(response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
            }else{
        axios.get('https://rank2top.com/api/abook/' + id)
                .then(response => {
                    setBook(response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
            }
        }, []);
        useEffect(() => {
        if (!login) {
        navigate('/register');
        }
        }, [])
        return (
                <>
<section className="text-center shadow section section-mins nnrr">
    <div className="row">
        <div className="col-lg-12 p-5 bg-primarys">
            <div className="row m-0">
                <h6 className="text-whites text-left">
                    <i className="fa fa-check-circle mr-2" aria-hidden="true"></i> Great Choice, { login ? login.name : <div></div> }
                </h6>
            </div>
        </div>
        <div className="col-lg-12 p-0 mt-5 mb-5">
            <div className="row m-0">
                <div className="col-lg-6">
                    <h5 className="mb-3 startList">To get started, please follow these simple steps</h5>
                    <ul className="ulList">
                        <li>Download our mobile app: [App Store/Google Play Store link]
                            - If you have already installed our app, please ensure it is up to date.</li>
                        <li>Sign in to your account using the credentials you created during the purchase process.</li>
                        <li>Navigate to the "Library" or "My Books" section within the app.</li>
                        <li>Locate the eBook, [eBook Title], in your library and tap on it to open.</li>
                        <li>You can start reading the eBook directly within the app. Enjoy the content at your convenience!</li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <h5 className="mb-3 startList">Alternatively, if you prefer to read the eBook on our website by follow these steps</h5>
                    <ul className="ulList">
                        <li>Make sure you have already signed in to the website account using the same credentials you used during the purchase.</li>
                        <li>Click on my profile on the top right corner and then click on accounts </li>
                        <li>Navigate to the " My eBooks" section.</li>
                        <li>Locate the eBook, [eBook Title], in your library and tap on it to open.</li>
                        <li>You can start reading the eBook directly and enjoy the content at your convenience!</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-lg-12 p-0 mt-5 mb-5">
        <div className="row text-left m-0">
            <div className="col-lg-6">
                <h5 className="mb-3">Stay on track with learning reminders</h5>
                <p className="mb-5">A little practice every day adds up. Set a learning reminder in the mobile app to meet your goals faster</p>
                <h6 className="mb-3">Scan QR Code for download Rank2Top mobile app</h6>
                <img className="centerimage width-40" src="https://rank2top.com/assets/web_assets/images/qr.png" />
                <h6 className="mb-3">Or download from the app store</h6>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.iottech.rank2top&amp;hl=en"><img width="100px" src="https://rank2top.com/assets/web_assets/images/play.png" /></a>
            </div>
            <div className="col-lg-6">
                <img className="width-100" src="https://rank2top.com/assets/web_assets/images/googleplay.png" />
            </div>
        </div>
        <div className="col-lg-12 bg-blue-dark mt-5 mb-5 p-5">
        <div className="row m-0">
            <div className="col-lg-4 p-md-0 p-3">
                <img className="width-100" src={ book.img } />
            </div>
            <div className="col-lg-8 text-left p-3">
                <h5 className="mb-3">{ book.title }</h5>
                <p className="mb-5">By Rank2Top</p>
                <h6 className="mb-3">Your Progress</h6>
                <a target="_blank" className="wl-100" class="mb-5" href="https://play.google.com/store/apps/details?id=com.iottech.rank2top&amp;hl=en"></a>
                { ids == 3 ?
                        <a className="btn-100" href={ '/../mybook/' + id }>Start Reading</a>
                :
                    <a className="btn-100" href={ '/../myaudio/' + id }>Start Reading</a>
                }
            </div>
        </div>
    </div>
    </div>
    </div>
</section>
</>
);
};

export default Ordersuccess;



