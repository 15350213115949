import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import Aimg from './../assets/img/audio.png';
import Rateview from './components/Rateview';
import Loader from './components/Loader';
import { useCartContext }
from './Context';
import axios from 'axios';
import { useState, useEffect }
from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Link }
from "react-router-dom";  

const AudioBooks = () => {
    const {setPurchase} = useCartContext();   
    const [loader, setLoader] = useState(true);
    const [books, setBooks] = useState([]);
    const [cat, setCat] = useState([]);
    useEffect(() => {
    axios.get('https://rank2top.com/api/abook_list')
            .then(response => {
            setBooks(response.data.data);
    setLoader(false);
            })
            .catch(error => {
            console.error(error);
    setLoader(false);
            });
            axios.get('https://rank2top.com/api/ebook_cat_list')
            .then(response => {
            setCat(response.data.data);
    setLoader(false);
            })
            .catch(error => {
            console.error(error);
    setLoader(false);
            });
    }, []);
  return (
          <>
  <section className="section-breadcu padding-large">
    <div className="row">  
        <div className="col-md-12"> 
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                    <span>Home</span>
                    </Link>
                </li> 
                <li className="breadcrumb-item">
                    <span> Audio Books</span>
                </li>  
            </ul> 
        </div>  
    </div>   
</section>

<section className="section-min section product" id="products">
    <div className="paddinng-y overflow-hidden">
        <div className="row">
            {loader ? <Loader /> : "" }
            <div className="listItem">
            <div>
            <i className="fa fa-list nowshowm d-none d-sm-block mt-3 ml-3 pointer bright" aria-hidden="true"></i>
            </div>
            <div>
            <select className="ml-3 mt-3 pointer d-sm-block d-none form-check-input" id="basic-select">
                <option disabled="">Sort</option>
                <option value="4">Order: A to Z</option>
                <option value="1">Newest</option>
                <option value="2">Highest Rated</option>
                <option value="3">Most Popular</option>
            </select>
            </div>
            </div>
            <div className="col-md-3 catSide mt-0 mb-5 collection-filter d-sm-none" id="filter-hide">
                <i className="fa fa-times text-right d-none d-sm-block text-danger close-filter mb-4" aria-hidden="true"></i>
                <ul>
                    {cat.map((cats, index) => (
                        (index == 0) ? 
                        <li><a className="linkCat actives" href={ '../audio-books/' + cats.slug }>{ cats.title }</a></li>
                        : 
                        <li><a className="linkCat" href={ '../audio-books/' + cats.slug }>{ cats.title }</a></li>     
                    ))}
                </ul>
            </div>
            <div className="col-md-9">
            
                <div className="row bookSide">         
            {books.map(post => (
                     <div className="col-lg-3 col-xs-6 mb-5 text-center"> 
                            <span className="product-list-left pull-left">
                                <Link to={ "../audiobooks/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </Link>
                            </span> 
                            <Link className="row" to={ "../audiobooks/" + post.slug }>
                                <span className="col-lg-12 product-list-right pull-left">
                                    
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <Rateview val={ post.star } />
                                </span>
                            </Link> 
                            <a onClick={ () => setPurchase(post.slug, 9) } className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </div>
                    ))}
                </div>				
            </div>

        </div>
    </div>
</section>
  </>
            );
};

export default AudioBooks;



