import React,{useEffect,useState} from 'react';
import { Helmet }
from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import emp from './../assets/img/covers.svg';
import slider from './../assets/img/index-3-products/p1.jpg';
import Rateview from './components/Rateview';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import Loader from './components/Loader';
import { Outlet, Link, useNavigate, useSearchParams }
from "react-router-dom";
import { useCartContext } from './Context';
const Search = () => {
    const { setPurchase } = useCartContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("find")
    const [book, setBook] = useState([]);
    const [audio, setAudio] = useState([]);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        axios.get("https://rank2top.com/api/searchbook/" + id)
                .then(response => {
                    setBook(response.data.data);
                    setAudio(response.data.message);
                    setLoader(false);
                })
                .catch(error => {
                    console.error(error);
                    setLoader(false);
                });
    }, []);
    return (
            <>
            <section className="section-breadcu padding-large">
    <div className="row">
        <div className="col-md-12">
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                    <span>Home</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <span> Search</span>
                </li>
                <li className="breadcrumb-item">
                    <span> {id}</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<section className="section-description py-5 bg-white p-5">
 {loader ? <Loader /> : "" }
 <h3 className="mb-5">EBook Search Result...</h3>
        
            {
                    book.length > 0
                    ?
                <div className="row">
                    {book.map(post => (
                            <div className="col-lg-2 col-xs-6 mb-5 text-center"> 
                            <span className="product-list-left pull-left">
                                <Link to={ "../ebook/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </Link>
                            </span> 
                            <Link to={ "../ebook/" + post.slug }>
                                <span className="product-list-right pull-left">
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <Rateview val={ post.star } />
                                </span>
                            </Link> 
                            <a onClick={ () => setPurchase(post.slug, 3) } className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </div>
                            ))} 
                    </div>

                    :
                    <div className="row">
            <div className="col-lg-12 library-content empty book-list">
                <p>Welcome to Rank2Top! Your EBook Library is currently empty.</p>
            </div>
            <div className="col-lg-12 img-101 text-center">
                <img src={ emp } />
            </div>
        </div>
        }
        
        <h3 className="mb-5">Audio Book Search Result...</h3>
        
            {
                    audio.length > 0
                    ?
                <div className="row">
                    {audio.map(post => (
                            <div className="col-lg-2 col-xs-6 mb-5 text-center"> 
                            <span className="product-list-left pull-left">
                                <Link to={ "../audiobooks/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </Link>
                            </span> 
                            <Link to={ "../audiobooks/" + post.slug }>
                                <span className="product-list-right pull-left">
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <Rateview val={ post.star } />
                                </span>
                            </Link> 
                            <a onClick={ () => setPurchase(post.slug, 9) } className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </div>
                            ))} 
                    </div>

                    :
                    <div className="row">
            <div className="col-lg-12 library-content empty book-list">
                <p>Welcome to Rank2Top! Your Audio Library is currently empty.</p>
            </div>
            <div className="col-lg-12 img-101 text-center">
                <img src={ emp } />
            </div>
        </div>
        }
            
</section>
</>

);
};

export default Search;
