import { createContext, useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import reducer from "./Reducer";
import $ from "./../assets/ebook/js/jquery.js";
const CartContext = createContext();

const initialState = {
    rating: "",
    loader: false
};

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();
    const setRating = (star) => {
        dispatch({type: "SET_RATING_STAR", payload: star});
    };
    const logins = JSON.parse(localStorage.getItem('USER_INFO'));
    const setLogin = (email, password) => {
        dispatch({type: "loader", payload: true});
        axios
                .post("https://rank2top.com/api/tutor_login_api", {email, password})
                .then(response => {
                    if (response.data.type == 'success') {
                        localStorage.setItem("USER_INFO", JSON.stringify(response.data.login[0]));
                        const loginDetail = JSON.parse(localStorage.getItem('USER_INFO'));
                        if (loginDetail) {
                            dispatch({type: "loader", payload: false});
                            navigate("/")
                        }
                    } else {
                        dispatch({type: "loader", payload: false});
                        $(".smessage").addClass('merror');
                        $(".smessage").html("Email or Password is Incorrect").delay(5000).fadeOut('slow');
                    }
                })
    };
    
    const setPersonal = (id, name, type) => {
        dispatch({type: "loader", payload: true});
        axios
                .post("https://rank2top.com/api/personal_update", {id, name, type})
                .then(response => {
                    if (response.data.type == true) {
                        localStorage.removeItem('USER_INFO');
                        localStorage.setItem("USER_INFO", JSON.stringify(response.data.data[0]));
                        const loginDetail = JSON.parse(localStorage.getItem('USER_INFO'));
                        if (loginDetail) {
                            dispatch({type: "loader", payload: false});
                            $(".smessage").css('display','block');
                            $(".smessage").addClass('msuccess');
                            $(".smessage").html("Saved Successfully!").delay(5000).fadeOut('slow');
                        }
                    } else {
                        dispatch({type: "loader", payload: false});
                        $(".smessage").css('display','block');
                        $(".smessage").addClass('merror');
                        $(".smessage").html("Some Problem!").delay(5000).fadeOut('slow');
                    }
                })
    };
    
    const setPass = (phone, password, cpassword) => {
        dispatch({type: "loader", payload: true});
        if(password == cpassword){
        axios
                .post("https://rank2top.com/api/forgotpass", {phone, password, cpassword})
                .then(response => {
                    console.log(response)
                    if (response.data.type == 'success') {
                            dispatch({type: "loader", payload: false});
                            $(".smessage").css('display','block');
                            $(".smessage").addClass('msuccess');
                            $(".smessage").html("Saved Successfully!").delay(5000).fadeOut('slow');
                    } else {
                        dispatch({type: "loader", payload: false});
                        $(".smessage").css('display','block');
                        $(".smessage").addClass('merror');
                        $(".smessage").html("Some Problem!").delay(5000).fadeOut('slow');
                    }
                })
            }else{
                dispatch({type: "loader", payload: false});
                        $(".smessage").css('display','block');
                        $(".smessage").addClass('merror');
                        $(".smessage").html("Password & Confirm Password not matched!").delay(5000).fadeOut('slow');
            }
    };
    
    const setPromo = (id) => {
        if (!logins) {
            navigate('/register');
        } else {
            dispatch({type: "loader", payload: true});
            axios
                    .get("https://rank2top.com/api/promo_code_applied/"+ id)
                    .then(response => {
                        if (response.data.type == true) {
                            dispatch({type: "loader", payload: false});
                            var amount = $("#ebook_amount").val();
                            var disc = parseInt(parseInt(amount * response.data.data.rate)/100);
                            $(".promo-btn").attr("disabled", true);
                            $("#promo_code").attr("disabled", true);
                            $("#ebook_udf6").val(response.data.data.code);
                            $("#ebook_udf7").val(disc);
                            $("#ebook_amount").val(parseInt(amount - disc));
                            $(".discountT").html('₹'+disc);
                            $(".totalT").html('₹'+parseInt(amount - disc));
                            $(".smessage1").css('display','block');
                            $(".smessage1").addClass('msuccess');
                            $(".smessage1").html("Promo Code Successfully Applied!");
                        } else {
                            dispatch({type: "loader", payload: false});
                            $(".smessage1").css('display','block');
                            $(".smessage1").addClass('merror');
                            $(".smessage1").html("Incorrect Promo Code!").delay(5000).fadeOut('slow');
                        }
                    })
        }
    };
    
    
    const setPurchase = (id, ids) => {
        if (!logins) {
            navigate('/register');
        } else {
            dispatch({type: "loader", payload: true});
            var logemail = logins.email;
            var logmobile = logins.phone;
            axios
                    .post("https://rank2top.com/api/ebook_chk", {id, logemail, logmobile, ids})
                    .then(response => {
                        console.log(response)
                        if (response.data.type == true) {
                            dispatch({type: "loader", payload: false});
                            $(".smessage-"+id).css('display','block');
                            $(".smessage-"+id).addClass('merror');
                            $(".smessage-"+id).html("This Book Already Purchased!").delay(5000).fadeOut('slow');
                        } else {
                            dispatch({type: "loader", payload: false});
                            navigate('/buy/' + id + '/' + ids);
                        }
                    })
        }
    };
    
    const setRegister = (user_type, name, email, phone, password, c_password) => {
        dispatch({type: "loader", payload: true});
        axios
                .post("https://rank2top.com/api/tutor_register_api", {user_type, name, email, phone, password, c_password})
                .then(response => {
                    $(".smessage").html('');
                    if (response.data.type == 'success') {
                        dispatch({type: "loader", payload: false});
                        localStorage.setItem("USER_INFO", JSON.stringify(response.data.token[0]));
                        const loginDetail = JSON.parse(localStorage.getItem('USER_INFO'));
                        $(".smessage").removeClass('merror');
                        $(".smessage").addClass('msuccess');
                        $(".smessage").html(response.data.message).delay(5000).fadeOut('slow');
                        if (loginDetail) {
                            navigate("/")
                        }
                    } else {
                        dispatch({type: "loader", payload: false});
                        $(".smessage").removeClass('msuccess');
                        $(".smessage").addClass('merror');
                        if (response.data.message.name) {
                            $("#userName").addClass('serror');
                            $(".smessage").append(response.data.message.name + '<br/>');
                        } else {
                            $("#userName").removeClass('serror');
                        }
                        if (response.data.message.email) {
                            $("#userEmail").addClass('serror');
                            $(".smessage").append(response.data.message.email + '<br/>');
                        } else {
                            $("#userEmail").removeClass('serror');
                        }
                        if (response.data.message.phone) {
                            $("#userMobile").addClass('serror');
                            $(".smessage").append(response.data.message.phone + '<br/>');
                        } else {
                            $("#userMobile").removeClass('serror');
                        }
                        if (response.data.message.password) {
                            $("#userPassword").addClass('serror');
                            $(".smessage").append(response.data.message.password + '<br/>');
                        } else {
                            $("#userPassword").removeClass('serror');
                        }
                        if (response.data.message.c_password) {
                            $("#usercPassword").addClass('serror');
                            $(".smessage").append(response.data.message.c_password);
                        } else {
                            $("#usercPassword").removeClass('serror');
                        }
                    }
                })
    };
    const setPayment = async(payu, email, phone, name, pinfo, amount, furl, surl, key, salt, txnid, udf5, udf4, udf3, udf2, udf6, udf7) => {
        dispatch({type: "loader", payload: true});
        if (payu == 2) {
            var amt = amount - udf7;
            axios
                    .post("https://rank2top.com/api/payuhash", {email, phone, name, pinfo, amt, key, salt, txnid, udf5, udf4, udf3, udf2, udf6, udf7})
                    .then(response => {
                        $('#ebook_hash').val(response.data['success']);
                if(udf6 != ''){
                            axios
                                .get("https://rank2top.com/api/promo_code_use/"+ udf6 + '/' + udf7 + '/' + txnid)
                                .then(resp => {
                                    
                            })
                }
                        axios
                                .post("https://rank2top.com/api/payu", {
                                    key: key,
                                    txnid: txnid,
                                    hash: response.data['success'],
                                    amount: amt,
                                    firstname: name,
                                    email: email,
                                    phone: phone,
                                    productinfo: pinfo,
                                    udf2: udf2,
                                    udf3: udf3,
                                    udf4: udf4,
                                    udf5: udf5,
                                    udf6: udf6,
                                    udf7: udf7,
                                    surl: surl,
                                    furl: furl
                                }, {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    },
                                }).then((result) => {
                            window.location.href = result.data;
                        })

                    })
        }
    };
    const setLogout = () => {
        localStorage.removeItem('USER_INFO');
        window.location.reload();
    };

    return (
            <CartContext.Provider
                value={{
                                ...state,
                                setRating,
                                setLogin,
                                setRegister,
                                setLogout,
                                setPayment,
                                setPurchase,
                                setPersonal,
                                setPass,
                                setPromo,
                            }}
                >
                {children}
            </CartContext.Provider>
            );
};

const useCartContext = () => {
    return useContext(CartContext);
};

export { CartProvider, useCartContext };
