import $ from './jquery.js';
$(document).ready(function () {

    var playing = false;
    $(document).on('click', '.playAudio', function () {
        var src = $(this).attr('ids');
        $('#player').attr('src', src);
        if (playing == false) {
            document.getElementById('player').play();
            playing = true;
            $(this).html('<i class="fa fa-pause-circle-o" aria-hidden="true"></i>');
        } else {
            document.getElementById('player').pause();
            playing = false;
            $(this).html('<i class="fa fa-play-circle-o" aria-hidden="true"></i>');
        }


    });

    $(document).on('click', '.nowshowm', function () {
        $(this).toggleClass("bright");
        $('#filter-hide').toggleClass("d-none");
        $('#filter-hide').removeClass("d-sm-none");
    });
    
    $(document).on('click', '.close-filter', function () {
        $('#filter-hide').addClass("d-sm-none");
    })
});

