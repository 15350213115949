import React from 'react';
import { Helmet } from "react-helmet";
import $ from "./../assets/ebook/js/jquery.js";
import Slider from "react-slick";
import Rating from './components/Rating';
import ReadMore from './components/ReadMore';
import Rateview from './components/Rateview';
import slider from './../assets/img/index-3-products/p1.jpg';
import slider2 from './../assets/img/index-3-products/p3.jpg';
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import Loader from './components/Loader';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { useCartContext } from './Context';
import { Outlet, Link, useParams }
from "react-router-dom";
        const Ebook = () => {

const settings = {
dots: false,
        lazyLoad: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
        {
        breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                }
        },
        {
        breakpoint: 600,
                settings: {
                slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                }
        },
        {
        breakpoint: 480,
                settings: {
                slidesToShow: 2,
                        slidesToScroll: 1
                }
        }
        ]
};
        let { id } = useParams()
        const [loader, setLoader] = useState(true);
        const [book, setBook] = useState([]);
        const [rook, setRook] = useState([]);
        const {setPurchase} = useCartContext();
        useEffect(() => {
        axios.get('https://rank2top.com/api/ebook/' + id)
                .then(response => {
                setBook(response.data.data);
        setRook(response.data.data);
                setLoader(false);
                })
                .catch(error => {
                console.error(error);
                setLoader(false);
                });
        }, []);
        const [books, setBooks] = useState([]);
        useEffect(() => {
        axios.get('https://rank2top.com/api/ebook_list/' + id)
                .then(response => {
                setBooks(response.data.data);
                setLoader(false);
                })
                .catch(error => {
                console.error(error);
                setLoader(false);
                });
        }, []);
        const {rating} = useCartContext();
        const reviewSubmit = e => {
        e.preventDefault()
                axios
                .post("https://rank2top.com/api/reviewSubmit", { name, ids, email, rating, message, types })
                .then(response => {
                if (response.data.type == true){
                document.getElementById("ebook-rating").reset();
                        $(".smessage").removeClass('merror');
                        $(".smessage").addClass('msuccess');
                        $(".smessage").html(response.data.message).delay(5000).fadeOut('slow');
                        $(".commentSec").prepend('<section class="row bg-white hrsec padding-large">'
                        + '<div class="col-lg-12 review-wrapper">'
                        + '<span class="rating-area rat-extra">' + response.data.data.star + '</span>'
                        + '<p class="comment">' + response.data.data.message + '</p>'
                        + '<p class="commentBy">by ' + response.data.data.name + ' on ' + response.data.data.dates + '</p>'
                        + '</div>'
                        + '</section>');
                setLoader(false);
                } else{
                $(".smessage").removeClass('msuccess');
                        $(".smessage").addClass('merror');
                        $(".smessage").html(response.data.message).delay(5000).fadeOut('slow');
                        setLoader(false);
                }
                })
        }
const myFunction = () => {
var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");
        if (dots.style.display === "none") {
dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
} else {
dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
}
}
const ratShow = () => {
var collapse = document.getElementById("collapses");
        if (collapse.style.display === "none") {
$(".collapse").show();
} else{
$(".collapse").hide();
}
}

const hideShow = () => {
    var txt = $(".contentm").is(':visible') ? 'Read More' : 'Read Less';
    console.log(txt)
    $(".show_hide").text(txt);
    $(".contentm").toggleClass("visible");
}
const [name, setName] = useState()
        const ids = book.id
        const [email, setEmail] = useState()
        const [rate, setRate] = useState()
        const [message, setMessage] = useState()
        const types = 3
        const [showMore, setShowMore] = useState(false);
        return (
                <>
<section className="section-breadcu padding-large">
    <div className="row">
        <div className="col-md-12">
            <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                        <span>Home</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to={ "../shop/" + book.cSlug }>
                        <span> { book.cTitle }</span>
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <span> { book.title }</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<section className="section-description py-5 bg-white padding-large">
    <div className="row">
        <div className="col-md-3">
            <div className="main-product-image">
                <div className="item-image" data-force-track="" data-track-info="{ & quot; description & quot; : & quot; coverImage & quot; }">
                    <div className="image-actions image-container product-type-icon-container book">
                        <img className="cover-image  notranslate_alt book-image" alt={ book.title } src={ book.img } />
                        <div className="product-type-icon"></div>   
                    </div>
                </div>
            </div>
            <Rateview val={ book.star } />({ book.starT })
        </div>
        <div className="col-md-6">
            <div className="item-info">
                <h1 className="title product-field">{ book.title }</h1>
                <div className="abouts">
                    <p className="author no-border">
                        <span className="authors product-field contributor-list">
                            <span className="contributor-name-prefix">by </span>
                            <span className="visible-contributors">
                                <span className="mobile-library-tag">Author</span>
                                <a href="#" className="contributor-name">Rank2Top</a>
                            </span>
                        </span>
                    </p>
                </div>
                <div className="item-synopsis" id="synopsis">
                    <h2 className="synopsis-title">Synopsis</h2>
                    <div className="contentm" dangerouslySetInnerHTML={{__html: book.description}}></div>
                    <a onClick={()=>hideShow()} href="#" class="show_hide" data-content="toggle-text">Read More</a>
                </div>             
            </div>
        </div>
        <div className="col-md-3">
            <div className="pricing-details">  
                <div className="box">
                    <h2 className="pricing-title">Buy the eBook</h2>
                    <div className="skeleton-shimmer hidden">
                        <div className="skeleton"><div className="white-line"></div></div>
                    </div>
                    <div className="pricing-figures">
                        <div className="original-price">
                            List Price
                            <div className="price-wrapper" translate="no">
                                <span className="price" data-bind="text: priceDetails.listPrice">₹{ book.price }</span>        
                            </div>
                        </div>
                        <div className="active-price">Your price
                            <div className="price-wrapper" translate="no">
                                <span className="price" data-bind="text: priceDetails.displayPrice">₹{ book.dprice }</span>
                            </div>
                        </div>
                    </div>
                    <div className="action-container">
                        <a onClick={ () => setPurchase(book.slug, 3) } className="purchase-action buy-now">
                            <span className="text">Buy Now</span>
                        </a>
                        <div className={ 'col-lg-12 smessage-' + book.slug } />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="section-similar bg-white padding-large">
    <div className="row">
        <div className="col-md-12">
            <h3 className="section-heading">Books related to { book.title }</h3>
        </div>
        <div className="col-md-12">
            <div className="product-list-sliderd">
                <ul className="swiper-wrappers product-list product-list-vertical">
                    <Slider {...settings}>
                        {books.map(post => (
                <li className="swiper-slidess text-center"> 
                            <span className="product-list-left pull-left">
                                <a href={ "../ebook/" + post.slug }>
                                    <img alt="product image" className="product-list-primary-img" src={ post.img } /> 
                                </a>
                            </span> 
                            <a href={ "../ebook/" + post.slug }>
                                <span className="product-list-right ex-related pull-left">
                                    <span className="product-list-name h4 black-color">{ post.title } </span>
                                    <span className="product-list-price"><i className="fa fa-inr" aria-hidden="true"></i>{ post.dprice }</span>
                                    <span className="product-list-price sell-p"><del><i className="fa fa-inr" aria-hidden="true"></i>{ post.price }</del></span>
                                    <span className="rating-area">
                                        <Rateview val={ post.star } />
                                    </span>
                                </span>
                            </a> 
                            <a onClick={ () => setPurchase(post.slug, 3) }  className="btn btn-default add-item" data-image={ slider }>
                                buy now
                            </a> 
                            <div className={ 'col-lg-12 smessage-' + post.slug } />
                        </li>
                ))}   
                    </Slider>
                </ul>
            </div>
        </div>
    </div>
</section>
<section className="row bg-white hrsec padding-large">
    <div className="col-lg-12">
        <h2 className="header">
            Ratings and Book Reviews ({ book.starT })
        </h2>
    </div>
</section>
<section className="row bg-white hrsec padding-large">
    <div className="col-lg-3">
        <h3 className="heading">Overall rating</h3>
        <h3 className="heading">{ book.star } out of 5</h3>
        <Rateview val={ book.star } />
    </div>
    <div className="col-lg-4 rating-stats-container">
        <dl className="rating-histogram-wrapper">
            <dt className="star-label">
                <span translate="no">5</span> Stars
            </dt>
            <dd>
                <div className="histogram-bar" id="rating-bar-row-5" star-count="1" rating-value="5">
                    <span className="histogram-bar-full" style={{width :  (book.starT5/book.starT)*100 +"%"  }}></span>
                </div>
                <span className="rating-count" id="rating-count-row-5" translate="no">
                    { book.starT5 }<span className="sr-only"> reviews have <span translate="no">5</span>
                        stars</span>
                </span>
            </dd>
            <dt className="star-label">
                <span translate="no">4</span> Stars
            </dt>
            <dd>
                <div className="histogram-bar" id="rating-bar-row-4" star-count="0" rating-value="4">
                    <span className="histogram-bar-full" style={{width :  (book.starT4/book.starT)*100 +"%"  }}></span>
                </div>
                <span className="rating-count" id="rating-count-row-4" translate="no">
                    { book.starT4 }<span className="sr-only"> reviews have <span translate="no">4</span>
                        stars</span>
                </span>
            </dd>
            <dt className="star-label">
                <span translate="no">3</span> Stars
            </dt>
            <dd>
                <div className="histogram-bar" id="rating-bar-row-3" star-count="0" rating-value="3">
                    <span className="histogram-bar-full" style={{width :  (book.starT3/book.starT)*100 +"%"  }}></span>
                </div>
                <span className="rating-count" id="rating-count-row-3" translate="no">
                    { book.starT3 }<span className="sr-only"> reviews have <span translate="no">3</span>
                        stars</span>
                </span>
            </dd>
            <dt className="star-label">
                <span translate="no">2</span> Stars
            </dt>
            <dd>
                <div className="histogram-bar" id="rating-bar-row-2" star-count="0" rating-value="2">
                    <span className="histogram-bar-full" style={{width :  (book.starT2/book.starT)*100 +"%"  }}></span>
                </div>
                <span className="rating-count" id="rating-count-row-2" translate="no">
                    { book.starT2 }<span className="sr-only"> reviews have <span translate="no">2</span> stars
                    </span>
                </span>
            </dd>
            <dt className="star-label">
                <span translate="no">1</span> Star
            </dt>
            <dd>
                <div className="histogram-bar" id="rating-bar-row-1" star-count="1" rating-value="1">
                    <span className="histogram-bar-full" style={{width :  (book.starT1/book.starT)*100 +"%"  }}></span>
                </div>
                <span className="rating-count" id="rating-count-row-1" translate="no">
                    { book.starT1 }<span className="sr-only"> reviews have <span translate="no">1</span> stars</span>
                </span>
            </dd>
        </dl>
    </div>
    <div className="col-lg-5">
        <h3 className="heading">Share your thoughts</h3>
        <div className="review-container">
            <a onClick={ratShow} className="purchase-action review-now">
                <span className="text">Write Your Review</span>
            </a>
        </div>
    </div>
</section>
<section className="row bg-white hrsec padding-large collapse" id="collapses" style={{display: "none"}}>
    <div className="col-lg-12">
        <div className="mb-14" id="customer-review">
            <h3 className="header">Write Your Review</h3>
            <form className="product-review-form smooth-submit" method="post" id="ebook-rating" onSubmit = { reviewSubmit }>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group mb-7">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Name*</label>
                            <input value={ book.id } className="form-control" type="hidden" name="ids" />
                            <input value="3" className="form-control" type="hidden" name="types" />
                            <input value={name} onChange={e => setName(e.target.value)} required="true" id="reviewName" className="form-control" type="text" name="name" />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group mb-4">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">Email*</label>
                            <input value={email} onChange={e => setEmail(e.target.value)} required="true" id="reviewEmail" type="email" name="email" className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <p className="mt-4 mb-4 fs-6 fw-semibold text-body-emphasis">Your Rating*</p>
                        <Rating />
                    </div>
                    <div className="col-sm-8">
                        <div className="form-group mb-10">
                            <label className="mb-4 fs-6 fw-semibold text-body-emphasis">How was your overall experience?</label>
                            <textarea value={message} onChange={e => setMessage(e.target.value)} id="reviewMessage" className="form-control" name="message" rows="5"></textarea>
                        </div>
                    </div>
                    <div className="col-sm-12 mb-10">
                        <button type="submit" className=" btn btn-dark btn-hover-bg-primary btn-hover-border-primary px-11">Submit</button>
                    </div>
                </div>
            </form>
            <div className="smessage"></div>
        </div>
    </div>
</section>
<section className="row bg-white hrsec padding-large">
    <div className="col-lg-12">
        <h3 className="header">All Book Reviews</h3>
    </div>
</section>
<div className="commentSec">
{loader ? <Loader /> : "" }
    {book.review?.map(posts => (
    <section className="row bg-white hrsec padding-large">
        <div className="col-lg-12 review-wrapper">
            <Rateview val={ posts.rate } />
            <p className="comment">
                { posts.message }
            </p>
            <p className="commentBy">by { posts.name } on { posts.dates }</p>
        </div>
    </section>
    ))}
</div>
<section className="row bg-white hrsec padding-large">
    <div className="col-lg-12">

    </div>
</section>

</>
);
};

export default Ebook;


